<template>
  <el-card id="encryptSwitch">
    <header>
      <h2>
        <div style="display: flex; align-items: center">
          号码检测
          <el-tooltip
            class="item"
            effect="dark"
            content="点击查看详情说明"
            placement="top"
          >
            <img
              v-if="!showTitleText"
              src="@/assets/images/what.png"
              alt=""
              style="width: 30px; height: 30px; cursor: pointer"
              @click="showTitleText = true"
            />
          </el-tooltip>
        </div>
        <div v-if="showTitleText">
          (为了快速确认号码的安全性，提前知道号码的类型，可设置号码检测。
          使用需要联系客服或管理员开通此功能，没有开通也不影响正常使用拨号。)
        </div>
        <el-descriptions
          class="margin-top"
          direction="vertical"
          border
        >
          <el-descriptions-item v-if="rateInfo">
            <template slot="label"> 费率 </template>
            {{ rateInfo.rate }}
          </el-descriptions-item>
          <el-descriptions-item v-if="roleName != '企业'">
            <template slot="label"> 今天已使用金额 </template>
            {{ surplus.money || 0 }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 今天已使用次数 </template>
            {{ surplus.count }}
          </el-descriptions-item>
        </el-descriptions>
      </h2>
      <div>
        <div class="copyTitle" style="line-height: 30px; margin-bottom: 10px">
          批量粘贴号码
          <el-button
            icon="el-icon-plus"
            type="primary"
            size="mini"
            @click="addAllNumber"
            >批量添加号码</el-button
          >
        </div>
        <el-input
          type="textarea"
          @keyup.enter.native="addAllNumber"
          :autosize="{ minRows: 2, maxRows: 6 }"
          placeholder="例:
          13882758658
          13882758658
          13882758658
          13882758658
          13882758658
"
          v-model="numberList"
        >
        </el-input>
      </div>
    </header>
    <main>
      <div class="staff-title" style="line-height: 30px">号码列表</div>
      <div class="search">
        <div class="left">
          <div class="left-wrap">
            <el-input
              v-model="numValue"
              @keyup.enter.native="addNumber"
              @input="numValue = numValue.replace(/^(0+)|[^\d]+/g, '')"
              placeholder="请输入号码(可按回车添加)"
              maxlength="11"
              show-word-limit
              clearable
            ></el-input>
          </div>
        </div>
        <div class="right">
          <el-button
            type="primary"
            icon="el-icon-plus"
            size="mini"
            class="reset"
            @click="addNumber"
            >添加号码</el-button
          >
        </div>
      </div>
      <div class="roll">
        <!-- 白名单 -->
        <div class="white-wrap">
          <div class="hbtn">
            <h3>
              《已添加的号码》(需要在上方操作，将号码添加后才可检测号码)
              <el-button
                icon="el-icon-refresh-right"
                type="primary"
                size="mini"
                @click="numList = []"
                >重置</el-button
              >
            </h3>
            <el-button type="primary" @click="testAll"
              >点击开始检测所有号码</el-button
            >
          </div>

          <div class="staff">
            <div class="staff-list">
              <div v-if="numList.length">
                <el-popover
                  placement="top-start"
                  trigger="hover"
                  @click.stop=""
                  :content="'点击单独检验号码 <' + v + '>'"
                  v-for="(v, i) in numList"
                  :key="i"
                >
                  <el-button
                    slot="reference"
                    @click="testing(v)"
                    style="margin-right: 10px; margin-bottom: 10px"
                  >
                    <el-popconfirm
                      :title="'确认要删除号码<' + v + '>吗?'"
                      @confirm="delNumber(i)"
                    >
                      <i
                        slot="reference"
                        class="el-icon-error"
                        @click.stop
                        style="color: red; font-size: 18px"
                      ></i>
                    </el-popconfirm>
                    {{ v }}</el-button
                  >
                </el-popover>
              </div>

              <el-empty v-else description="暂未添加号码"></el-empty>
            </div>
          </div>
        </div>

        <!-- 黑名单 -->
        <div class="white-wrap">
          <div class="hbtn">
            <h3>《已检测的号码》</h3>
            <el-button type="primary" @click="tableArr = []"
              >清空列表</el-button
            >
          </div>

          <div class="staff">
            <div class="staff-list">
              <!-- tableArr -->
              <div v-if="tableArr.length">
                <el-descriptions
                  class="margin-top"
                  size="medium"
                  border
                  v-for="v in tableArr"
                  :key="v.id"
                >
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-mobile-phone"></i>
                      手机号
                    </template>

                    {{ v.phone }}
                  </el-descriptions-item>
                  <el-descriptions-item>
                    <template slot="label">
                      <i class="el-icon-paperclip"></i>

                      号码状态</template
                    >

                    <el-tag :size="getTag(v)">{{ getStatus(v) }}</el-tag>
                  </el-descriptions-item>
                </el-descriptions>
              </div>

              <el-empty v-else description="暂无已检测号码"></el-empty>
            </div>
          </div>
        </div>
      </div>
    </main>
  </el-card>
</template>

<script>
import { ElMessage } from "@/assets/js/elementui-api";
let Message = new ElMessage();

import { find, list, findMoney } from "@/api/NnumberDetection";
export default {
  data() {
    return {
      numberList: null,
      // 1 有效号码，2 无效号码，3 未查得
      showTitleText: false,
      numValue: null,
      numList: [],
      tableArr: [],
      roleName: null,
      rateInfo: {},
      surplus: {},
    };
  },
  created() {
    this.$nextTick(async function () {
      this.roleName = window.localStorage.getItem("roleName");
      this.queryRate();
      this.queryMoney();
    });
  },
  computed: {},
  inject: ["reload"],
  methods: {
    // 查询费率
    async queryRate() {
      let params = {};
      if (this.roleName == "企业") {
        params.parentId = window.localStorage.getItem("id");
      }
      let res = await list(params);
      console.log("查询费率", res.data.data);
      if (res.data.data) {
        this.rateInfo = res.data.data[0];
      }
    },
    // 查询
    async queryMoney() {
      let params = {
        // "sta": "2022-12-23", //开始时间 不填 默认当天
        // "end": "2022-12-30" //结束时间
      };
      if (this.roleName == "企业") {
        params.parntId = window.localStorage.getItem("id");
      }
      if (this.roleName != "企业") {
        params.parntId = window.localStorage.getItem("parentId");
        params.userId = window.localStorage.getItem("id");
      }

      let res = await findMoney(params);
      console.log("查询剩余金额", res.data.data);
      if (res.data.statusCode == "00000") {
        this.surplus = { ...res.data.data };
      }
      console.log("this.surplus", this.surplus);
    },
    // 获得号码标签样式
    getTag(v) {
      if (v.status == 1) {
        return "success";
      } else if (v.status == 2) {
        return "warning";
      } else {
        return "info";
      }
    },
    // 获得号码状态
    getStatus(v) {
      if (v.status == 1) {
        return "有效号码";
      } else if (v.status == 2) {
        return "无效号码";
      } else {
        return "未知";
      }
    },
    // 批量添加号码
    addAllNumber() {
      let arr = this.numberList.split(/[(\r\n)\r\n]+/);
      arr.pop();
      let regex =
        /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/;
      console.log("arr", arr);
      arr.map((v) => {
        if (!regex.test(v)) {
          Message.warning("有号码的格式不对！已过滤");
        } else {
          this.numList.push(v);
          this.numList = Array.from(new Set(this.numList));
        }
      });
    },
    // 添加号码的回车事件
    addNumber() {
      var regex =
        /^(13[0-9]{9})|(15[0-9]{9})|(17[0-9]{9})|(18[0-9]{9})|(19[0-9]{9})$/;
      if (!regex.test(this.numValue)) {
        Message.warning("请录入正确的手机号码！");
      } else {
        this.numList.push(this.numValue);
        this.numList = Array.from(new Set(this.numList));
        this.numValue = null;
      }
    },
    // 检验所有号码
    async testAll() {
      const loading = await this.$loading({
        lock: true,
        text: "检验号码中,请稍等...",
      });
      let params = {
        phone: this.numList.join(),
      };
      let res = await find(params);
      if (res.data.statusCode == "00000") {
        this.tableArr = res.data.data;
        loading.close();
      } else {
        loading.close();
        Message.error(res.data.message);
      }
    },
    // 单个检验号码
    async testing(number) {
      let params = {
        phone: number + "",
      };
      let res = await find(params);
      if (res.data.statusCode == "00000") {
        this.tableArr = res.data.data;
      } else {
        Message.error(res.data.message);
      }
    },
    // 删除单个号码
    delNumber(index) {
      console.log("删除单个号码");
      this.numList.splice(index, 1);
    },
  },
};
</script>

<style lang="less" scoped>
.copyTitle {
  font-weight: bold;
  font-size: 18px;
}
#encryptSwitch {
  header {
    margin-bottom: 20px;
    h1 {
      margin-bottom: 10px;
      font-weight: normal;
      > div {
        font-size: 18px;
        color: #606369;
        margin-top: 10px;
      }
    }
    
  }
  main {
    min-height: 500px;
    .search {
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      // justify-content: space-between;
      //   flex-direction: column;
      .left {
        display: flex;
        align-items: center;
        height: 100%;
        flex-wrap: wrap;
        line-height: 30px;
        .left-wrap {
          display: flex;
          align-items: center;
          margin-right: 10px;
          span {
            margin: 10px;
            // width: 30%;
            text-align: right;
            align-items: center;
            font-size: 12px;
          }
        }
      }
      .right {
        // margin-top: 20px;
        text-align: right;
        display: flex;
        /* justify-content: space-between; */
        justify-content: flex-end;
        align-items: center;
        height: 100%;
      }
    }
    .hbtn {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ccc;
      margin-bottom: 15px;
    }
    .white-wrap,
    .black-wrap {
      // border: 3px solid #f6f6f6;
      box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
      width: 48%;
      min-height: 400px;
      padding: 20px;
      box-sizing: border-box;
      border-radius: 20px;
    }
    h3 {
      font-weight: normal;
    }
    .roll {
      display: flex;
      // align-items: center;
      justify-content: space-between;
    }
    .staff-data {
      margin-left: 41px;
    }
    div /deep/.causeShow {
      margin-left: 485px;
    }
    #evictionPolicy /deep/ .el-date-editor {
      width: 75% !important;
    }

    .title {
      font-weight: normal;
      margin-bottom: 50px;
    }
    .btns {
      min-width: 15vw;
    }
    .btn {
      width: 90px;
      height: 40px;
    }
    .wrap,
    .status {
      margin-bottom: 30px;
    }
    .time-call {
      max-width: 85vw;
      justify-content: space-around;
    }
    .time-call > div {
      align-items: center;
    }
    .time-call > div > div {
      margin-right: 5px;
    }
    .status {
      align-items: center;
    }
    .status-title {
      width: 10%;
    }
    .status-list > label {
      align-items: center;
      margin-right: 30px;
    }
    .staff-title {
      font-weight: bold;
      font-size: 18px;
    }
    .staff-list {
      flex: 1;
    }
    .staff-list-data {
      flex-wrap: wrap;
    }
    .wrap,
    .time-call,
    .time-call > div,
    .status,
    .status-list,
    .staff,
    .staff-list-data {
      display: flex;
    }
  }
}
</style>